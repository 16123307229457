<template>
    <div>
        <delete-group-form 
            v-if="deleteFormShow" 
            :group="deleteFormData" 
            @close="deleteFormShow = false" 
            @acceptDeleteGroup="deleteGroup($event.group_id)"
        />
        <group-full-card 
            v-if="groupCardShow"
            :group="selectedGroup"
            @close="groupCardShow = false; updateGroups()"
            @updateGroup="updateGroups()"
        />
        <new-group-form v-if="newFormShow" @updateGroups="updateGroups" @close="newFormShow = false"/>
        <menu-bar 
            @addGroup="newFormShow = true"
            @search="searchInput = $event"
        />
        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[16px]">
            <div class="text-white">
                <span class="text-[28px] font-semibold">{{ allGroups.length }}</span> груп
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ allTrainers.length }}</span> тренерів
            </div>
        </div>
        <div class=" border-[#E4E4EF] w-[90%] mx-auto rounded-[10px] overflow-auto">
            <table class="w-full">
                <thead class="select-none">
                    <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                        <th class="px-3 py-2 text-start font-medium text-sm w-[15%]">Назва групи</th>
                        <th class="px-3 py-2 w-[20%] text-start font-medium text-sm">Тренер</th>
                        <th class=" py-2 w-[15%] text-start font-medium text-sm">Кількість учнів</th>
                        <th class="px-3 w-[50%] py-2 text-start font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody v-if="visibleGroups.length > 0">
                    <group-row
                        v-for="group in visibleGroups"
                        :group="group"
                        @openGroupCard="selectedGroup = group; groupCardShow = true"
                        @updateGroups="updateGroups"
                        @deleteGroup="deleteFormData = group; deleteFormShow = true"
                    />
                </tbody>
            </table>
        </div>
        <div class="w-full mt-[14px] flex items-center justify-center" v-if="visibleGroups.length == 0">
            <p class="text-[#A3A3C2] text-[16px]">Групи не знайдено</p>
        </div>  
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getAllGroups, deleteGroupRequest, getAllTrainers, getTrainerGroupsRequest, getAssistantGroupsRequest } from '@/desktopApp/services/apiRequests'
    import { useMainStore } from '@/desktopApp/stores/mainStore'

    import MenuBar from './components/MenuBar.vue'
    import GroupCard from './components/GroupCard.vue'
    import NewGroupForm from './components/NewGroupForm.vue'
    import GroupFullCard from './components/GroupFullCard.vue'
    import GroupRow from './components/GroupRow.vue'
    import DeleteGroupForm from './ui/DeleteGroupForm.vue'

    export default {
        name: 'groups',

        setup() {
            const store = useMainStore()
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const allGroups = ref([])
            const visibleGroups = ref([])
            const newFormShow = ref(false)
            const selectedGroup = ref({})
            const groupCardShow = ref(false)
            const deleteFormShow = ref(false)
            const deleteFormData = ref({})
            const searchInput = ref('')
            const allTrainers = ref([])

            const deleteGroup = async (groupId) => {
                await deleteGroupRequest(groupId)
                await updateGroups()
            }

            const updateGroups = async () => {
                // const res = await getAllGroups()
                // allGroups.value = res.data
                // visibleGroups.value = res.data
                const resTrainers = await getAllTrainers()
                allTrainers.value = resTrainers.data

                allGroups.value = store.trainer_groups
                visibleGroups.value = store.trainer_groups
            }

            watch(searchInput, async (newValue) => {
                if (newValue === '') {
                    visibleGroups.value = allGroups.value
                } else {
                    visibleGroups.value = allGroups.value.filter(group => {
                        return group.name.toLowerCase().includes(newValue.toLowerCase())
                    })
                }
            })

            onMounted(() => {
                updateGroups()
            })
            
            return {
                apiURL,
                allGroups,
                newFormShow,
                updateGroups,
                selectedGroup,
                groupCardShow,
                deleteGroup,
                deleteFormShow,
                deleteFormData,
                searchInput,
                visibleGroups,
                allTrainers
            }
        },

        components: {
            MenuBar,
            GroupCard,
            NewGroupForm,
            GroupFullCard,
            GroupRow,
            DeleteGroupForm
        }
    }
</script>
