<template>
    <div class="fixed bg-[#252526] w-full h-full top-0 left-0">
        <div @click="$emit('close')" class="flex text-[#BD9135] items-center ml-3 mt-5">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div class="uppercase font-bold text-white text-center mt-[10px]">
            ТЕСТУВАННЯ - {{ standard.name }}
        </div>

        <div class="uppercase font-bold text-white text-center mt-[10px]">
            група {{ players[0].group_name }}
        </div>

        <div class="mx-[20px] h-[70vh] overflow-y-auto mt-[30px] pb-[10px]">

            <div v-if="players.length > 0" v-for="student in players" @click="" class="flex justify-between items-center mb-[10px]">
                <div class="flex w-[70%]">
                    <div class="mr-[10px]">
                        <div class="w-[43px] overflow-hidden h-[43px] flex items-end justify-center rounded-full border-[#FFF] border-[2px]">
                            <img v-if="student.photo?.length > 0" :src="apiURL + 'static/' + student.photo">
                            <div v-else class="w-full h-full bg-[#FFF] bg-opacity-40 text-[#FFF] text-[16px] font-semibold flex justify-center items-center">
                                {{ student.name[0] + student.surname[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="w-[75%]">
                        <div class="text-[#FFF] font-semibold truncate">
                            {{ student.name }} {{ student.surname }}
                        </div>
                        <div>
                            <div class="truncate text-[10px] text-gray-400">{{ '#' + student.player_number + ' | ' + student.amplua + ' | ' + student.age + 'p. | ' + student.nationality }}</div>
                        </div>
                    </div>
                </div>

                <div>
                    <div v-if="student.tested" @click="$emit('restartTesting', student)" class="bg-[#474849] cursor-pointer mx-1 h-[18px] pt-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                        TESTED
                    </div>
                    <div v-else @click="$emit('startTesting', student)" class="bg-[#0ABFC2] cursor-pointer mx-1 h-[18px] pt-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                        START
                    </div>  
                </div>

                <div v-if="student.result">
                    <div v-if="student.result == 'excellent'" class="relative border-[1px] border-[#339989] w-[22px] h-[22px] rounded-full flex justify-center items-center">
                        <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] border-[1px] w-[17px] h-[17px] rounded-full bg-[#339989] border-[#339989]"></div>
                    </div>
                    <div v-if="student.result == 'good'" class="relative border-[1px] border-[#BD9135] w-[22px] h-[22px] rounded-full flex justify-center items-center">
                        <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] border-[1px] w-[17px] h-[17px] rounded-full bg-[#BD9135] border-[#BD9135]"></div>
                    </div>
                    <div v-if="student.result == 'bad'" class="relative border-[1px] border-[#DA4167] w-[22px] h-[22px] rounded-full flex justify-center items-center">
                        <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] border-[1px] w-[17px] h-[17px] rounded-full bg-[#DA4167] border-[#DA4167]"></div>
                    </div>
                </div>
            </div>

            <div v-else class="text-center text-[#FFF] my-[100px]">
                В групі нема учнів
            </div>

        </div>

        <div class="py-[20px] border-t-[1px] mx-[15px]">
            <div class="text-center font-bold text-[#FFF] mt-[15px]">
                ПРОТЕСТОВАНО - <span class="font-bold">{{ players.filter(e => e.tested).length }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useMainStore } from '../stores/mainStore';

export default {
    name: "StandardCarryingForm",

    props: {
        standard: {
            type: Object,
            required: true
        },
        players: {
            type: Array,
            required: true
        }
    },

    setup() {
        const store = useMainStore()

        const apiURL = process.env.VUE_APP_API_URL

        onMounted(() => {

        })
    
        return {
            apiURL
        }
    }
}
</script>

<style>
    
</style>